import getConfig from 'next/config';

const fetchData =  (params = {}) => {
  const {publicRuntimeConfig:{MANAGEMENT_URL} } = getConfig() || { }
  let {query = ''} = params
  if (query.includes("article")) {
    query = encodeURI(query)
  }
  const url = `${MANAGEMENT_URL}/${query}`;
  return fetch(url ,params)
}

export default fetchData